<template>
    <div class="theory-list">
      <div class="theory-list-header" style="padding-top: 20px">
          状态
          <el-select v-model="stateValue" style="width: 200px;margin-left: 10px" placeholder="请选择" @change="selectState">
            <el-option
                v-for="item in stateList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
      </div>
      <el-table class="customTable" :data="studentList"
                style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
                :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
<!--        <el-table-column v-if="role === 4" prop="student_account" align="center" label="学生账号"></el-table-column>
        <el-table-column v-else prop="student_name" align="center" label="学生名字"></el-table-column>-->
        <el-table-column prop="exam_name" align="left" label="考试名称"></el-table-column>
        <el-table-column prop="status" align="center" label="状态"></el-table-column>
        <el-table-column prop="begin_time" align="center" label="考试时间"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="toMarkPaper(scope.row.student_theory_exam_log_id)" v-if="stateValue === 1">重新批改</el-link>
            <el-link type="primary" :underline="false" @click="toMarkPaper(scope.row.student_theory_exam_log_id)" v-if="stateValue === 2">批改</el-link>
            <span v-if="stateValue === 3">该学生暂未提交无法评分</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                     :current-page="listPages.currentPageNum"
                     :page-size="listPages.eachPageNum"
                     :total="listPages.total"
                     layout="prev, pager, next, jumper"
                     @current-change="listCurrentChange">
      </el-pagination>
    </div>
</template>

<script>
import {markGetMarkListJudge, markGetNoMarkListJudge, markGetNoSubmitListJudge ,markGetMarkListTeacher, markGetNoMarkListTeacher, markGetNoSubmitListTeacher} from '@/utils/apis'
export default {
  name: "Index",
  data(){
    return {
      role:Number(localStorage.getItem('role')) || '',
      studentList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      examId:this.$route.query.examId || '',
      stateValue:2,
      stateList:[
        {
          value:1,
          label:'已批改'
        },
        {
          value:2,
          label:'未批改'
        },
        {
          value:3,
          label:'未提交'
        },
      ],


    }
  },
  mounted() {
    this.getStudentList();
  },
  methods:{
    // 获取学生列表
    getStudentList(){
      let params = {
        exam_id:this.examId, //考试列表接口暂无，写死测试
        paging:1,
        page:this.listPages.currentPageNum,
        pageSize:this.listPages.eachPageNum
      }
      // 如果是教师端
      if(this.role === 3){
        // params.exam_id = 68; // 考试列表接口没有  写死测试
        if(this.stateValue === 1){
          markGetMarkListTeacher(params).then((res)=>{
            this.studentList = res.data.list;
            this.listPages.total = res.data.sum
          }).catch((err)=>{
            console.log('err',err)
          })
        } else if(this.stateValue === 2){
          markGetNoMarkListTeacher(params).then((res)=>{
            this.studentList = res.data.list;
            this.listPages.total = res.data.sum
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          markGetNoSubmitListTeacher(params).then((res)=>{
            this.studentList = res.data.list;
            this.listPages.total = res.data.sum
          }).catch((err)=>{
            console.log('err',err)
          })
        }
      } else {
        // 如果是评委端
        if(this.stateValue === 1){
          markGetMarkListJudge(params).then((res)=>{
            this.studentList = res.data.list;
            this.listPages.total = res.data.sum
          }).catch((err)=>{
            console.log('err', err)
          })
        } else if(this.stateValue === 2){
          markGetNoMarkListJudge(params).then((res)=>{
            this.studentList = res.data.list;
            this.listPages.total = res.data.sum
          }).catch((err)=>{
            console.log('err', err)
          })
        } else {
          markGetNoSubmitListJudge(params).then((res)=>{
            this.studentList = res.data.list;
            this.listPages.total = res.data.sum
          }).catch((err)=>{
            console.log('err', err)
          })
        }
      }
    },
    selectState(){
      this.listPages.currentPageNum = 1;
      this.getStudentList()
    },
    // 批改试卷
    toMarkPaper(id){
      if(this.role === 3){
        this.$router.push({
          path:'/evaluationCenter/theoreticalQuestions/markTheoryPaper',
          query:{
            type:this.stateValue,
            logId:id,
            examId:this.examId
          }
        })
      } else {
        this.$router.push({
          path:'/judges/theoreticalQuestions/markTheoryPaper',
          query:{
            type:this.stateValue,
            logId:id,
            examId:this.examId
          }
        })
      }
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getStudentList();
    },
  }
}
</script>

<style scoped lang="scss">
.theory-list{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .theory-list-header{
    display: flex;
    align-items: center;
    span{
      line-height: 1;
    }
  }
}
</style>